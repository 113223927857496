.container {
  max-width: 80%;
  margin: auto;
  /* margin-top: 100px; */
  margin-bottom: 100px;
  color: white;
}

.container h3 {
  opacity: 0.8;
  margin-left: 5px;
}

.fightsList {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.fightsListItem {
  margin-right: 1rem;
  margin-bottom: 1rem;
}