.container {
  margin: auto;
  margin-top: 100px;
  width: 80%;
  color: white;
}

.backBtn {
  color: white;
  font-weight: 700;
  text-decoration: none;
}

.backBtn:hover {
  text-decoration: underline;
}

.backBtn::before {
  content: "\1438";
  margin-right: 5px;
  font-size: 20px;
}