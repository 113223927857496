.previewContainer {
  background-color: #2A3536;
  color: white;
  padding: 1rem;
  border-radius: 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  padding-bottom: 30px;
}

.info {
  margin-bottom: 30px;
  margin-left: 10px;
  text-align: left;
}

.info p {
  margin-bottom: -10px;
}

.info a {
  text-decoration: underline;
  background-color: transparent;
  color: var(--color-primary-200);
}

.info p::before {
  content: "\2687"; /* Unicode character for envelope symbol */
  margin-right: 5px;
  font-size: 20px;
}

.editBtn {
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  margin: 30px 0;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
}

.editBtn:hover {
  box-shadow: 0 0 5px white;
}