.container {
  max-width: 80%;
  margin: auto;
  /* margin-top: 100px; */
  margin-bottom: 100px;
  color: white;
}

.container h3 {
  opacity: 0.8;
  margin-left: 5px;
}

.container button {
  cursor: pointer;  
  font: inherit;
  text-decoration: none;
  padding: 0.5rem 1rem;
  width: fit-content;
  display: block;
  text-align: center;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
}

.container button:hover {
  box-shadow: 0 0 5px white;
}

.notificationsList {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.notificationsListItem {
  margin-right: 1rem;
  margin-bottom: 1rem;
}