.navbar {
  background-color: rgb(36, 36, 36);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.navbarhome {
  /* background-color: transparent; */
  background-color: rgb(36, 36, 36);
  font-weight: 700;
}

.navbarhomeScrolled {
  background-color: rgb(36, 36, 36);
}

.navlogo {
  max-width: 105px;
  margin-top: 10px;
  height: auto;
}

.navbarcontainer {
  width: 80%;
  margin: 0 auto;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
}

.navbarleft,
.navbarright {
  display: flex;
}

.navbarleft a,
.navbarright a {
  text-decoration: none;
  color: #ffffff
}

.leftlink a:hover,
.leftlink a.active,
.rightlink a:hover,
.rightlink a.active {
  border-bottom: 3px solid white;
}

.register {
  display: inline-block;
  padding: 5px 25px;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  border-radius: 4px;
}

.leftlink {
  font-weight: 500;
  margin-top: 50px;
}

.rightlink {
  font-weight: 500;
  margin-top: 6px;
}

ul li a.register {
  color: var(--color-primary-01);
}

.register:hover {
  box-shadow: 0 0 10px #fff;
}

.navbarleft li {
  list-style: none;
  margin-right: 40px;
}

.navbarright li {
  list-style: none;
  margin-left: 40px;
}

.overlay {
  display: none;
}

.unreadBadge {
  display: inline-block;
  background-color: var(--color-primary-05);
  color: white;
  font-weight: 700;
  border-radius: 50%;
  padding: 0px 8px;
  font-size: 0.8em;
  margin-left: 5px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
  }

  .hamburger {
    display: block;
    position: fixed;
    margin-left: 20px;
    margin-top: 56px;
    z-index: 999;
    color: white;
    font-size: 30px;
    padding: 10px;
    background-color: rgb(36, 36, 36);
    border-radius: 10px;
  }

  .navbarcontainer {
    width: 100%;
    height: 100%;
    background-color: rgb(36, 36, 36);
  }

  .menu {
    position: fixed;
    height: 100%;
    /* top: 5%; */
    left: -100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 998;
  }

  .navlogo {
    display: none;
    /* max-width: 105px;
    margin-top: 10px;
    margin-left: -30px;
    height: auto; */
  }

  .navbarleft,
  .navbarright {
    flex-direction: column;
    margin-left: 40px;
  }

  .navbarleft {
    margin-top: 75px;
    margin-bottom: 40px;
  }

  .navbarleft li {
    list-style: none;
    margin-right: 0;
  }

  .navbarright li {
    list-style: none;
    margin-left: 0;
    margin-bottom: 40px;
  }

  .menu.showMenu {
    left: 0;
    width: 60%;
    background-color: rgb(36, 36, 36);
    height: 100%;
  }
}