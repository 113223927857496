.accounts {
  max-width: 80%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  color: white;
}

.accounts h1 {
  font-weight: 700;
}

.addLink {
  cursor: pointer;  
  font: inherit;
  text-decoration: none;
  /* margin-left: 5px; */
  padding: 0.5rem 1rem;
  width: fit-content;
  display: block;
  text-align: center;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
}

.addLink:hover {
  box-shadow: 0 0 5px white;
}

.accountsList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.accountsListItem {
  flex: 0 0 calc(33.333% - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.accountsListItem:nth-child(3n) {
  margin-right: 0; /* reset margin for every 3rd item */
}

.deleteAccountLink {
  text-decoration: underline;
  color: gray;
  font-size: 12px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .accountsListItem {
    flex: 0 0 100%; /* full width for smaller screens */
    margin-right: 0; /* reset margin-right for mobile */
  }
}