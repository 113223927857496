.card {
    position: fixed;
    top: 60px;
    right: 20px;
    background-color: rgb(36, 36, 36);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 15px;
    z-index: 1000;
    width: 500px;
    max-width: 500px;
    color: white;
    animation: slideIn 0.1s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.card ul {
    max-height: 50vh;
    overflow-y: auto;
}

.clearButton {
    font: inherit;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: var(--color-primary-01);
    color: var(--color-primary-400);
    border: none;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    flex-shrink: 0;
    margin-left: 10px;
    text-decoration: none;
}

.unreadDot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-primary-05);
    margin-right: 10px;
}

.clearButton:hover {
    opacity: 0.8;
}

.header {
    display: flex; 
    justify-content: space-between;
    align-items: center;
}

.notification {
    cursor: pointer;
    background-color: var(--color-primary-07);
    border: 1px solid var(--color-primary-07);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    padding: 2px 20px;
    margin: 20px 0;
    border-radius: 10px;
    line-height: 1.5;
}

.subject, .body {
    font-weight: bold;
}

.visible {
    opacity: 1;
}

@media (max-width: 768px) {
    .card {
        top: 60px; /* Keep it at a fixed distance from the top */
        left: 10px;
        right: 10px;
        width: auto;
        max-width: none;
        /* max-height: 50vh; */
        transform: none;
        margin-top: 0;
    }

    @keyframes slideIn {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translateY(0);
        }
    }

    .card ul {
        max-height: 50vh; /* Adjust based on your header height */
        overflow-y: auto;
    }

    .header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .clearButton {
        margin-left: 10px;
        margin-top: 0;
    }
}