.matchRequest {
  background-color: #2A3536;
  color: white;
  padding: 1rem 2rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty {
  font-weight: bold;
}

.matchRequest select {
  font: inherit;
  width: 100%;
  padding: 0.3rem;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgb(29, 29, 29);
  color: var(--color-primary-03);
  margin-bottom: 10px;
}

.matchRequest button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 1.5rem;
  margin-top: 25px;
  margin-bottom: 10px;
  border-radius: 11px;
  background-color: var(--color-primary-01);
  color: var(--color-primary-400);
  border: none;
  display: block;
  width: 100%;
  font-size: 25px;
  font-weight: 700;
}

.matchRequest button:hover {
  box-shadow: 0 0 10px #fff;
}

.matchRequest button:disabled {
  background-color: gray;
  cursor: not-allowed;
}


