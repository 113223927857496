.background {
  background-image: url('../../../public/authbackground.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.authlogo {
  display: block;
  margin: auto;
  max-width: 200px;
  height: auto;
}

.container {
  max-width: 60%;
  background: rgb(36, 36, 36, 0.98);
  box-shadow: 0px 3.60031px 65.7057px 12.6011px rgba(0, 0, 0, 0.95);
  border-radius: 17px;
  margin: auto;
  margin-top: 200px;
  opacity: 0.98;
}

.form {
  max-width: 80%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 100px;
  color: var(--color-primary-03);
  font-weight: 700;
  margin-bottom: 100px;
}

.form h1,
.form h4 {
  text-align: center;
}

.subheading {
  font-weight: 500;
  opacity: 0.5;
}

.form label,
.form input,
.form textarea {
  display: block;
  width: 100%;
  color: var(--color-primary-03);
}

.form input,
.form textarea {
  font: inherit;
  padding: 0.3rem;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.form hr {
  border: 0;
  height: 1px;
  max-width: 70%;
  background-color: lightgray;
  margin: 25px auto;
  opacity: 0.1;
}

.form select {
  font: inherit;
  width: 100%;
  padding: 0.3rem;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  color: var(--color-primary-03);
}

.resetaddress {
  text-decoration: underline;
  color: var(--color-primary-03);  
  font-weight: 500;
  font-size: 14px;
  opacity: 0.5;
}

.resetaddress:hover {
  cursor: pointer;
}

.actions {
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.actions button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 1.5rem;
  margin-top: 25px;
  margin-bottom: 10px;
  border-radius: 11px;
  background-color: var(--color-primary-01);
  color: var(--color-primary-400);
  border: none;
  display: block;
  width: 100%;
  font-size: 25px;
}

.actions a {
  text-decoration: underline;
  background-color: transparent;
  color: var(--color-primary-03);  
  font-weight: 500;
  font-size: 14px;
  opacity: 0.5;
}

.actions button:hover {
  box-shadow: 0 0 10px #fff;
}

.addressList {
  margin-top: 10px;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 5px 10px;
  max-height: 200px; 
  overflow-y: auto; 
  -webkit-overflow-scrolling: touch; /* Ensures smooth scrolling on iOS */
  scrollbar-width: thin; /* Firefox */
}

.addressList li {
  cursor: pointer;
  padding: 4px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.addressList li:last-child {
  border-bottom: none;
}

.phoneInput {
  display: flex;
}

.areaCode {
  max-width: 70px;
  text-align: center;
  margin-right: 5px;
}

.errors {
  max-width: 60%;
  margin: auto;
  border-radius: 10px;
  padding: 0px 20px;
  border: 2px solid var(--color-error-100);
}

.errors p {
  text-align: center;
}

.errors ul {
  text-align: center;
  margin: 1rem 0rem 1rem 0rem;
}

.bottomLinks {
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    max-width: 90%;
    margin-top: 100px;
  }
}