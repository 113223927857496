.confirm {
  background-color: #2A3536;
  color: white;
  padding: 1rem;
  width: 20rem;
  border-radius: 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

.confirm p {
  text-align: center;
}

.confirmInput {
  margin: auto;
  align-items: center;
  text-align: center;
  font-weight: bold;
}

.confirmInput input,
.confirmInput select {
  font: inherit;
  width: 75%;
  padding: 0.3rem;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: darkslategray;
  color: var(--color-primary-03);
  margin: 10px 0;
}

.confirmInput select,
.confirmInput option {
  text-align: center;
}

.confirmations {
  display: flex;
  justify-content: center;
}

.confirm h2 {
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.confirm button {
  margin: 10px 10px;
  width: 100%;
  cursor: pointer;
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  color: white;
}

.confirm button:hover {
  opacity: 0.5;
}

.yesBtn {
  background-color: #4EB84C;
}

.noBtn {
  background-color: #F85C5C;
}

.errors {
  /* max-width: 60%; */
  margin: auto;
  margin-top: 25px;
  border-radius: 10px;
  padding: 0px 20px;
  border: 2px solid var(--color-error-100);
}

.errors p {
  text-align: center;
  font-weight: bold;
}

.errors ul {
  text-align: center;
  margin: 1rem 0rem 1rem 0rem;
}