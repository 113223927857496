.fighterList {
  flex: 1;
  width: 90%;
  margin: auto;
}

.fighterList h2 {
  text-align: center;
}

.fighterList input {
  display: block;
  margin: auto;
  border-radius: 10px;
  font: inherit;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #2A3536;
  color: white;
}

.searchBar {
  width: 100%;
  padding: 0.5rem;
}

.noResults {
  opacity: 0.8;
}

.fighter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid gray;
}

.fighterName {
  font-size: 25px;
}

.fighter a, .selectedFighter a {
  text-decoration: none;
  color: inherit;
}

.fighter a:hover, .selectedFighter a:hover {
  text-decoration: underline;
}

.selectedFighter {
  background-color: rgb(252, 72, 72);
}

.fighterInfo p {
  font-weight: 500;
  opacity: 80%;
  /* font-size: 80%; */
  line-height: 10px;
}

.fighter button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 1.5rem;
  margin-top: 25px;
  margin-bottom: 10px;
  border-radius: 11px;
  background-color: var(--color-primary-01);
  color: var(--color-primary-400);
  border: none;
  display: block;
  font-size: 20px;
  font-weight: 700;
}

.fighter button:hover {
  box-shadow: 0 0 5px white;
}

.gym {
  margin-right: 5px;
  font-size: 20px;
  margin-top: -10px;
}

.weightClass::before {
  content: "\1F3CB"; /* Unicode character for location symbol */
  margin-right: 5px;
  font-size: 20px;
}

.professional::before {
  content: "\1F4B8";
  margin-right: 5px;
  font-size: 20px;
}

.experience::before {
  content: "\1F5D3"; /* Unicode character for clock symbol */
  margin-right: 5px;
  font-size: 20px;
}

.proRecord::before {
  content: "\1F534";
  margin-right: 5px;
  font-size: 20px;
}

.amateurRecord::before {
  content: "\1F7E2";
  margin-right: 5px;
  font-size: 20px;
}

.region::before {
  content: "\1F4CD";
  margin-right: 5px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .fighter {
    display: block;
    margin: auto;
  }

  .fighter button {
    width: 100%;
  }
}