.heroContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.heroImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.heroTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-left: 150px;
  margin-bottom: 150px;
  color: white;
}

.heroHeading {
  font-size: 50px;
  margin-bottom: 10px;
  font-weight: 700;
}

.heroParagraph {
  font-size: 22px;
  margin-bottom: 50px;
}

.heroParagraph p {
  margin-bottom: -15px;
}

.heroCTA {
  font-size: 20px;
  font-weight: 700;
  padding: 12px 24px;
  background-color: var(--color-primary-01);
  color: #ffffff;
  text-decoration: none;
  border-radius: 8px;
}

.servicesSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

.servicesHeading {
  font-size: 33px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 50px;
}

.serviceContainers {
  display: flex;
  justify-content: center;
  gap: 100px;
}

.serviceContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 20px;
  border-radius: 20px;
  background-color: #2A3536;
  color: #ffffff;
}

.serviceContainer.middle {
  background-color: var(--color-primary-01);
}

.serviceTitle {
  font-size: 24px;
  margin-bottom: 10px;
}

.serviceDescription {
  font-size: 16px;
  text-align: center;
}

.missionSection {
  display: flex;
  align-items: stretch;
  padding: 75px 150px;
  color: #ffffff;
}

.missionText {
  flex: 1;
  /* Take up available space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

.missionList {
  list-style-type: circle;
  padding-left: 20px;
}

.missionText h2 {
  font-weight: 700;
  font-size: 33;
}

.previewVideo {
  border-radius: 10px;
  width: 100%;
  height: auto;
  border: 1px solid #333;
  background-color: #333;
  max-width: 750px;
  margin: 30px auto;
  cursor: pointer;
}

.missionImageContainer {
  flex: 1;
  /* Take up available space */
  overflow: hidden;
  border-radius: 10px;
}

.missionImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.benefitsSection {
  padding: 50px 0;
  color: white;
}

.benefitsContainer {
  display: flex;
  justify-content: space-evenly;
  max-width: 1100px;
  margin: 0 auto;
}

.benefitsColumn {
  flex: 1;
  padding: 0 15px;
  box-sizing: border-box;
}

.benefitsTitle {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 20px;
}

.benefitsList {
  list-style-type: none;
  padding-left: 20px;
}

.benefitsItem {
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  padding-left: 35px;
  font-size: 14px;
  background-position: left center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .heroContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: auto;
    margin-top: 100px;
  }

  .heroImage {
    display: none;
  }

  .heroTextContainer {
    align-items: center;
    text-align: center;
    margin: 20px 0;
  }

  .heroHeading {
    font-size: 35px;
  }

  .heroParagraph {
    font-size: 18px;
  }

  .heroCTA {
    padding: 10px 20px;
  }

  .servicesSection,
  .missionSection,
  .benefitsSection {
    max-width: 80%;
    margin: auto;
    padding: 25px 15px;
  }

  .serviceContainers,
  .benefitsContainer {
    flex-direction: column;
    gap: 30px;
  }

  .serviceContainer,
  .benefitsColumn {
    width: 100%;
  }

  .homeImg1 {
    max-width: 100%;
    /* Limit width to the viewport width */
    height: auto;
    /* Adjust height automatically */
  }

  .missionSection {
    flex-direction: column;
    /* Stack items vertically */
    padding: 50px 30px;
    /* Adjust padding for smaller screens */
  }

  .missionText {
    flex: none;
    /* Remove flex sizing */
    width: 100%;
    /* Full width */
    padding: 0 15px;
    /* Adjust padding */
    margin-bottom: 20px;
    /* Add some space between the text and image */
    order: 2;
  }

  .missionText,
  .missionImageContainer {
    padding: 0;
  }

  .missionImageContainer {
    margin-top: 20px;
    order: 1;
  }

  .previewVideo {
    width: 100%;
    height: auto;
    /* Ensure the video keeps its aspect ratio */
    margin: 20px auto;
    /* Adjust the margin for mobile */
  }

  .previewVideo {
    width: 100%;
    height: auto;
    /* Ensure the video keeps its aspect ratio */
    margin: 20px auto;
    /* Adjust the margin for mobile */
  }

  .benefitsItem {
    background-size: 20px 20px;
    padding-left: 30px;
    font-size: 14px;
  }
}