.form {
  max-width: 40%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  color: white;
}

.form h1,
.form p {
  text-align: center;
}

.actions {
  margin-left: 10px;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.actions button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  border: none;
}

.actions a {
  text-decoration: underline;
  background-color: transparent;
  color: white;
  opacity: 0.4;
}
