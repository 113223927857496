* {
  box-sizing: border-box;
}

:root {
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  background-color: rgb(36, 36, 36);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --color-primary-100: #1C3738; /* Modal bacgkround */
  --color-primary-200: #8BAAAD; /* Links active and hovered */
  --color-primary-300: #000F08; /* Nav bar background */
  --color-primary-400: #F4FFF8; /* Main bacgkround color */

  --color-primary-01: #b91414; /* Nav bar background */
  /* --color-primary-01: #F85C5C; */
  --color-primary-02: #0E111E; /* Page form background */
  --color-primary-03: #EBF1FF; /* Subheadings */
  --color-primary-04: #2f2f2f; /* Page form background */
  --color-primary-05: #F85C5C;
  --color-primary-06: rgb(36, 36, 36);;
  --color-primary-07: #2A3536;
  --color-error-100: #EB4034;
  --color-error-200: #FF948C;

  --color-success-100: #03FC17;
  --color-success-200: #B1FCB3;
}

body {
  margin: 0;
  overflow-x: hidden;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Style for the options in the dropdown */
select option {
  background-color: #333; /* Dark background */
  color: white;           /* White text */
}

@media (max-width: 768px) {
  h1, h2, h3 {
    line-height: 1.5;
  }
}
