.content {
    /* display: flex; */
    margin-top: 100px;
    color: white;
    width: 80%;
    margin: auto;
    margin-top: 100px;
    text-align: left;
    align-items: Stretch;
}

.imageContainer {
    width: 90%;
    margin: auto;
    align-items: center;
}

.advertiseImage {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: contain;
    display: block;
}

.email::before {
    content: "\2709";
    /* Unicode character for envelope symbol */
    margin-right: 5px;
    font-size: 20px;
}

.actions {
    width: 50%;
    margin: auto;
}

.actions button {
    font: inherit;
    cursor: pointer;
    padding: 1rem 1.5rem;
    margin: 40px 0px;
    border-radius: 11px;
    background-color: var(--color-primary-01);
    color: var(--color-primary-400);
    border: none;
    display: block;
    width: 100%;
    font-size: 25px;
    font-weight: bold;
}

.actions a {
    text-decoration: underline;
    background-color: transparent;
    color: var(--color-primary-03);
    font-weight: 500;
    font-size: 14px;
    opacity: 0.5;
}

.actions button:hover {
    box-shadow: 0 0 10px #fff;
}

.benefits {
    margin-bottom: 50px;
}

.benefitsSection {
    display: flex;
    justify-content: center;
    gap: 100px;
    margin-top: 20px;
}

.benefitContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding: 20px;
    border-radius: 20px;
    background-color: #2A3536;
    color: #ffffff;
}

.benefitContainer.middle {
    background-color: var(--color-primary-01);
}

.benefitTitle {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 1.2;
}

.benefitDescription {
    font-size: 16px;
    text-align: center;
}

@media (max-width: 768px) {
    .content {
        width: 100%;
    }

    .imageContainer {
        width: 100%;
    }

    .advertiseImage {
        border-radius: 0;
    }

    .actions {
        width: 80%;
    }

    .benefitsSection {
        flex-direction: column;
        gap: 15px;
        align-items: center;
    }

    .benefitContainer {
        max-width: 80%;
    }

    .benefitContainer.middle {
        background-color: #2A3536;
    }
}