.container {
  height: 100vh;
  width: 80vw;
  margin: auto;
  margin-top: 100px;
  color: white;
}

.container h1 {
  text-align: center;
  margin-bottom: 50px;
}

.filtersContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 50px;
}

.filtersContainer label {
  font-weight: 700;
  font-size: 17px;
}

.filtersContainer input
.filtersContainer textarea {
  font: inherit;
  width: 100%;
  padding: 0.3rem;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  color: var(--color-primary-03);
}

.catchWeightRange {
  display: flex;
}

.catchWeightRange input {
  font: inherit;
  width: 75%;
  padding: 0.3rem;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  color: var(--color-primary-03);
}

.catchWeightRange input:disabled {
  opacity: 75%;
}

.filtersContainer select {
  font: inherit;
  width: 100%;
  padding: 0.3rem;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  color: var(--color-primary-03);
}

.tabsContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.tab {
  background-color: #2a3536;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 5px;
  padding: 10px 20px;
  text-align: center;
}

.tab:hover {
  background-color: var(--color-primary-01);;
}

.activeTab {
  background-color: var(--color-primary-01);;
}

.splitContainer {
  display: flex;
  flex-direction: row;
}

.split {
  width: 50%;
  overflow: auto;
  height: 100%;
}

.mobile {
  display: none;
}

@media (max-width: 768px) {
  .filtersContainer {
    flex-direction: column;
    gap: 0;
  }

  .catchWeightRange {
    flex-direction: column;
    justify-content: space-between;
  }

  .filtersContainer select {
    margin-bottom: 15px;
  }

  .splitContainer {
    flex-direction: column;
  }

  .split {
    width: 100%;
    display: none; /* Hide both selectors initially */
  }

  .split.active { /* Class to toggle visibility */
    display: block;
  }

  .mobile {
    display: block;
  }

  /* Add styles for your back button here */
  .reselect {
    cursor: pointer;
  } 

  .reselect:hover {
    text-decoration: underline;
  }
}