.container {
    display: flex;
    flex-direction: column;
    height: 95vh;
    color: white;
    width: 90%;
    margin: auto;
    padding-top: 100px;
}

.inboxContent {
    display: flex;
    flex: 1;
    color: white;
    overflow: hidden;
}

.inboxList {
    width: 33.33%;
    overflow-y: auto;
    padding-right: 20px;
    position: relative;
    /* border-radius: 10px; */
}

.inboxList::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    background: linear-gradient(to bottom, 
        rgba(204, 204, 204, 0) 0%,
        rgba(204, 204, 204, 1) 5%,
        rgba(204, 204, 204, 1) 95%,
        rgba(204, 204, 204, 0) 100%
    );
}

.messageDisplayContainer {
    width: 66.67%;
    padding: 0px 0px 20px 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.newMessageButton {
    font: inherit;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: var(--color-primary-01);
    color: var(--color-primary-400);
    border: none;
    font-size: 16px;
    font-weight: 700;
}

.newMessageButton:hover {
    box-shadow: 0 0 10px #fff;
}

.backButton {
    display: none;
}

@media (max-width: 768px) {
    .container {
        height: 85vh;
        padding-top: 80px;
    }

    .inboxContent {
        flex-direction: column;
        height: 100%;
    }

    .header {
        display: flex; 
    }

    .header.hideBar {
        display: none; 
    }

    .inboxList,
    .messageDisplayContainer {
        width: 100%;
        height: 100%;
        padding: 0;
        overflow-y: auto;
    }

    .inboxContent.showMessages .inboxList {
        display: none;
    }

    .inboxContent.showMessages .messageDisplayContainer {
        display: flex;
    }

    .inboxContent.hideMessages .messageDisplayContainer {
        display: none;
    }

    .backButton {
        display: none;
        padding: 10px;
        margin-bottom: 10px;
        background-color: var(--color-primary-01);
        color: var(--color-primary-400);
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
    }

    .inboxContent.showMessages .backButton {
        display: block;
    }
}