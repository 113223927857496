.inboxTile {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    position: relative;
    /* border-radius: 5px; */
    border-bottom: 1px solid #5f5f5f;
}

.inboxTile:hover {
    background-color: #2A3536;
}

.selectedInbox {
    background-color: #2A3536;
}

.avatarContainer {
    margin-right: 15px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.defaultAvatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.inboxInfo {
    flex-grow: 1;
    min-width: 0;
}

.inboxNameContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
}

.inboxName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
    flex: 1;
    margin: 0;
}

.lastMessageContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    font-size: 14px;
    overflow: hidden;
    min-height: 20px;
}

.senderName {
    font-weight: 600;
    margin-right: 5px;
    color: #999;
}

.messageContent {
    color: #999;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;
}

.messageTimestamp {
    font-size: 12px;
    color: #999;
}

.noMessages {
    font-style: italic;
    color: #999;
}

.specialBadge {
    background-color: var(--color-primary-05);
    color: white;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 12px;
    margin-left: 10px;
    font-weight: bold;
    flex-shrink: 0;
}

.groupBadge {
    background-color: var(--color-primary-05);
    color: white;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 12px;
    margin-left: 10px;
    font-weight: bold;
    flex-shrink: 0;
}

.unreadDot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-primary-05);
    margin-right: 5px;
}

/* .unreadDot {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ff0000;
    font-size: 12px;
} */

.unreadMessage {
    font-weight: bold;
    color: white;
}