.dropzone {
  border: 2px solid gray;
  padding: 20px;
  text-align: center;
}

.uploadLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.errors {
  color: red;
}