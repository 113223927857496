.footer {
    display: block;
    padding: 5px 0px 200px 0px;
    color: white;
    background-color: #1b2123;
    /* height: 100%; */
}

.container {
    display: flex;
    width: 60%;
    margin: auto;
    justify-content: space-between;
}

.advertisement {
    margin-top: -50px;
    margin-bottom: 20px;
}

.socials {
    display: inline-block;
}

.socials h3 {
    margin-bottom: 10px;
}

.socialLinks {
    margin-top: 0;
    margin-left: -5px;
}

.socialLinks img {
    max-width: 40px;
}

.privacyLink {
    text-decoration: underline;
    color: white;
}

.legalLinks {
    display: flex;
    flex-direction: column;
}

.email {
    font-weight: 600;
    display: flex;
    align-items: center;
}

.email::before {
    content: "\2709";
    margin-right: 5px;
    font-size: 30px;
    display: flex;
    align-items: center;
    transform: translateY(-15%);
}

@media (max-width: 768px) {
    .container {
        display: block;
    }
}