.container {
  margin-top: 75px;
  /* margin-bottom: -90px; */
}

.navbar {
  background-color: rgb(36, 36, 36);
  width: 100%;
  z-index: 999;
  margin-left: 20px;
}

.navbarcontainer {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.navbarleft {
  display: flex;
}

.navbarleft a {
  text-decoration: none;
  color: #ffffff
}

.leftlink a:hover,
.leftlink a.active {
  border-bottom: 3px solid white;
}

.leftlink {
  font-weight: 500;
}

.navbarleft li {
  list-style: none;
  margin-right: 40px;
}

.unreadBadge {
  display: inline-block;
  background-color: var(--color-primary-05);
  color: white;
  font-weight: 700;
  border-radius: 50%;
  padding: 0px 8px;
  font-size: 0.8em;
  margin-left: 5px;
  text-align: center;
}
