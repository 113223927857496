.content {
  text-align: center;
  margin-top: 100px;
  color: white;
}

.content a, 
.content p {
  text-decoration: underline;
  color: white;
  opacity: 0.4;
}