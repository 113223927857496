.container {
  border-radius: 20px;
  padding: 5px 20px;
  background-color: rgb(29, 29, 29);
}

.header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.minor {
  opacity: 0.7;
  font-size: 95%;
}

.promotionActions button {
  margin-right: 15px;
}

.championBelt {
  max-width: 100%;
  max-height: 50px;
  height: auto;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  margin: auto 0;
}


.firstSection {
  display: flex;
  gap: 20px;
}

/* .weightClass::before {
  content: "\1F3CB";
  margin-right: 5px;
  font-size: 20px;
}

.proRecord::before {
  content: "\1F534";
  margin-right: 5px;
  font-size: 20px;
}

.amateurRecord::before {
  content: "\1F7E2";
  margin-right: 5px;
  font-size: 20px;
} */

.actionButton {
  cursor: pointer;
  font: inherit;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
  font-size: 20px;
}

.actionButton:hover {
  box-shadow: 0 0 5px white;
}

.pulloutButton {
  cursor: pointer;
  font: inherit;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
  font-size: 15px;
}

.pulloutButton:hover {
  box-shadow: 0 0 5px white;
}

.eventDetails {
  margin-bottom: 1.5rem;
}

.eventDetails p {
  font-weight: 500;
}

.eventDetails a {
  font-weight: 700;
  text-decoration: none;
  color: inherit;
}

.location::before {
  content: "\1F4CD";
  /* Unicode character for location symbol */
  margin-right: 5px;
  font-size: 20px;
}

.dateTime::before {
  content: "\1F5D3";
  /* Unicode character for clock symbol */
  margin-right: 5px;
  font-size: 20px;
}

.isTitle {
  color: lightgreen;
}

.weightClass::before {
  content: "\1F3CB";
  /* Unicode character for location symbol */
  margin-right: 5px;
  font-size: 20px;
}

.fighters {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
}

.fighterBlock {
  display: flex;
}

.fighterPicture {
  margin-top: 10px;
  margin-right: 30px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.fighterInfo a,
.fighterInfo p {
  font-weight: 700;
  opacity: 0.9;
}

.fighterInfo a {
  text-decoration: none;
  color: inherit;
}

.fighterInfo a:hover {
  text-decoration: underline;
}

.eventName:hover {
  text-decoration: underline;
}

.accepted {
  color: lightgreen;
}

.pending {
  color: rgb(0, 195, 255);
}

.rejected {
  color: lightcoral;
}

/* Responsive Design */
@media (max-width: 768px) {
  .fighters {
    display: block;
  }

  .header {
    display: block;
  }

  .fighterBlock {
    display: block;
  }

  .championBelt {
    display: none;
  }


  .actionButton {
    width: 100%;
  }
}