.bannerAd {
    display: flex; /* Enable flexbox */
    justify-content: center; /* Horizontally center content */
    margin: auto;
    margin-top: 90px;
    width: 100%; /* Ensure it takes full width of the parent */
    text-align: center; /* Ensure text or image alignment */
    z-index: 9999999;
}

.bannerAd img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}