.background {
  background-image: url('../../../public/authbackground.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.authlogo {
  display: block;
  margin: auto;
  max-width: 200px;
  height: auto;
}

.container {
  max-width: 60%;
  background: rgb(36, 36, 36, 0.98);
  box-shadow: 0px 3.60031px 65.7057px 12.6011px rgba(0, 0, 0, 0.95);
  border-radius: 17px;
  margin: auto;
  margin-top: 200px;
  opacity: 0.98;
}

.form {
  max-width: 80%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 100px;
  color: var(--color-primary-03);
  font-weight: 700;
  margin-bottom: 100px;
}

.form h1 {
  text-align: center;
}

.form label,
.form input,
.form textarea {
  display: block;
  width: 100%;
  color: var(--color-primary-03);
}

.form input,
.form textarea {
  font: inherit;
  padding: 0.3rem;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.form hr {
  border: 0;
  height: 1px;
  max-width: 70%;
  background-color: lightgray;
  margin: 25px auto;
  opacity: 0.1;
}

.form select {
  font: inherit;
  width: 100%;
  padding: 0.3rem;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  color: var(--color-primary-03);
}

.fighterPicture {
  display: block;
  max-width: 150px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.sportsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  width: 100%;
}

.sportItem {
  display: flex;
  gap: 1rem; /* Adjust spacing between items as needed */
  margin-bottom: 1rem;
  font-weight: 600;
  align-items: center; /* Ensure items are centered vertically */
}

.sportItem input {
  margin-right: 0.5rem;
}

.sportItem label {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.statInputs {
  width: 95%;
  font-size: 95%;
  margin: auto;
  margin-top: 15px;
  background-color: rgb(48, 48, 48);
  padding: 20px;
  border-radius: 15px;
}

.catchWeightRange {
  max-width: 50%;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.actions {
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.actions button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 1.5rem;
  margin-top: 25px;
  margin-bottom: 10px;
  border-radius: 11px;
  background-color: var(--color-primary-01);
  color: var(--color-primary-400);
  border: none;
  display: block;
  width: 100%;
  font-size: 25px;
}

.actions button:hover {
  box-shadow: 0 0 10px #fff;
}

.link {
  text-decoration: underline;
  background-color: transparent;
  color: var(--color-primary-03);  
  font-weight: 500;
  font-size: 14px;
  opacity: 0.5;
}

.errors {
  max-width: 60%;
  margin: auto;
  padding: 0px 20px;
  border-radius: 10px;
  border: 2px solid var(--color-error-100);
}

.errors p {
  text-align: center;
}

.errors ul {
  text-align: center;
  margin: 1rem 0rem 1rem 0rem;
}

.success {
  max-width: 60%;
  margin: auto;
  border-radius: 10px;
  border: 2px solid var(--color-success-100);
}

.errors p,
.success p {
  text-align: center;
}

.errors ul,
.success p {
  text-align: center;
  margin: 1rem 0rem 1rem 0rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    max-width: 90%;
    margin-top: 100px;
  }

  .catchWeightRange {
    display: block;
  }
}