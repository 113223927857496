.info {
  gap: 1rem;
  font-weight: 500;
  margin-top: -20px;
  text-align: center;
}

.location::before {
  content: "\1F4CD"; /* Unicode character for location symbol */
  margin-right: 5px;
  font-size: 20px;
}

.dateTime::before {
  content: "\1F5D3"; /* Unicode character for clock symbol */
  margin-right: 5px;
  font-size: 20px;
}

.info h3::before {
  content: "\1F94A"; /* Unicode character for boxing glove symbol */
  margin-right: 5px;
  font-size: 20px;
}

.actions {
  background-color: #2A3536;
  color: white;
  padding: 1rem;
  border-radius: 6px;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.actions h2 {
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.actions button {
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
}

.acceptBtn {
  background-color: #4EB84C;
}

.acceptBtn:hover {
  background-color: #44a342;
}

.declineBtn {
  background-color: #F85C5C;
}

.declineBtn:hover {
  background-color: #d95555;
}