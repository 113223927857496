.content {
    color: white;
    width: 80%;
    margin: auto;
    margin-top: 100px;
    text-align: center;
    margin-top: 150px;
}

.content h3 {
    opacity: 75%;
}

.exploreLink {
    display: none;
}

.downloads {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.downloads a {
    display: inline-flex;
}

.downloads img {
    height: 100px;
    display: block;
}

/* Responsive Design */
@media (max-width: 768px) {
    .exploreLink {
        display: block;
        margin-top: 20px;
        opacity: 75%;
        text-decoration: underline;
        color: gray;
    }

    .downloads img {
        height: 60px;
    }
}