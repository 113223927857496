.explore {
  max-width: 80%;
  margin: auto;
  /* margin-top: 100px; */
  margin-bottom: 100px;
  color: white;
}

.explore h1 {
  font-weight: 700;
}

.search {
  display: flex;
}

.search input {
  display: block;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  font: inherit;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #2A3536;
  color: white;
}

.filters {
  margin-top: 13px;
  margin-left: 15px;
  display: inline-flex;
  font-weight: 600;
  font-size: 18px;
}

.filters div {
  display: flex;
  align-items: center;
}

.filters input[type="checkbox"] {
  margin-bottom: 12px;
  margin-right: 5px;
  transform: scale(1.5);
}

.filters label {
  margin-right: 15px;
  margin-left: 5px;
}

.radiusSearch {
  margin-top: 10px;
}

.radiusSearch label {
  font-weight: bold;
}

.radiusSearch input,
.radiusSearch select {
  display: inline-block;
  border-radius: 10px;
  font: inherit;
  padding: 7px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #2A3536;
  color: white;
}

.radiusSearch input {
  width: 25%;
}

.radiusSearch select {
  width: 100px;
}

.radiusSearch p {
  margin-top: 2px;
  margin-left: 2px;
}

.resetLocation {
  text-decoration: underline;
  color: var(--color-primary-03);  
  font-weight: 500;
  font-size: 14px;
  opacity: 0.5;
}

.resetLocation:hover {
  cursor: pointer;
}

.map {
  margin-top: 25px;
}

.itemsList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.itemsListItem {
  flex: 0 0 calc(33.333% - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.itemsListItem:nth-child(3n) {
  margin-right: 0; /* reset margin for every 3rd item */
}

/* Responsive Design */
@media (max-width: 768px) {
  .itemsListItem {
    flex: 0 0 100%; /* full width for smaller screens */
    margin-right: 0; /* reset margin-right for mobile */
  }

  .search {
    display: block;
  }

  .filters{
    margin-left: -5px;
  }

  .filters div {
    display: block;
  }
  
  .filters input[type="checkbox"] {
    transform: scale(1); /* Adjust as needed */
    margin-left: 15px;
  }

  .radiusSearch input {
    width: 65%;
    margin-right: 5px;
  }
  
  .radiusSearch select {
    width: 100px;
  }
}