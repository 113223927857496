.container {
    background-color: #2A3536;
    padding: 10px 20px;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

h1 {
    margin-bottom: 1rem;
}

.search {
    width: 100%;
    margin-bottom: 1rem;
}

.search input {
    display: block;
    margin-top: 1rem;
    width: 100%;
    border-radius: 10px;
    font: inherit;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: var(--color-primary-06);
    color: white;
}

.usersContainer {
    flex-grow: 1;
    overflow-y: auto;
    background-color: #2A3536;
    border-radius: 4px;
}

.userItem {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    background-color: #3A4546;
    padding: 10px;
    border-radius: 10px;
    font-weight: 700;
}

.userItem:last-child {
    border-bottom: none;
}

.checkbox {
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #8BAAAD;
    border-radius: 4px;
    margin-right: 1rem;
    cursor: pointer;
    flex-shrink: 0;
    position: relative; 
}

.checkbox:checked {
    background-color: var(--color-primary-01);
    border: none;
}

.checkbox:checked::after {
    content: '\2714';
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
}

.organisation {
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.continueButton,
.actionButton {
    font: inherit;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: var(--color-primary-01);
    color: var(--color-primary-400);
    border: none;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
}

.continueButton:hover,
.actionButton:hover {
    box-shadow: 0 0 10px #fff;
}

.continueButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.messageInput {
    width: 100%;
    height: 50%;
    margin-top: 20px;
    display: block;
    margin-top: 1rem;
    width: 100%;
    border-radius: 10px;
    font: inherit;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: var(--color-primary-06);
    color: white;
    font-size: 16px;
    resize: none;
    box-sizing: border-box;
}

.actionButton + .actionButton {
    margin-left: 10px;
}

.header {
    display: block;
    align-items: center;
    /* margin-bottom: 20px; */
}

.backButton {
    font: inherit;
    cursor: pointer;
    color: var(--color-primary-400);
    font-size: 16px;
    font-weight: 700;
    margin-right: 20px;
}

.actionButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}