.card {
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2A3536;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.card h1 {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: -10px;
}

.info {
  display: flex;
  gap: 1rem;
  font-size: 11px;
  font-weight: 500;
  color: white;
  opacity: 0.6;
}

.location::before {
  content: "\1F4CD"; /* Unicode character for location symbol */
  margin-right: 5px;
  font-size: 20px;
}

.dateTime::before {
  content: "\1F5D3"; /* Unicode character for clock symbol */
  margin-right: 5px;
  font-size: 20px;
}

.actionButton {
  cursor: pointer;
  font: inherit;
  width: 80%;
  margin-bottom: 20px;
  padding: 0.5rem 0rem;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
}

.actionButton:hover {
  box-shadow: 0 0 5px white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .info {
    flex-direction: column;
    gap: 0;
    margin: 10px 0;
  }

  .info p {
    margin-top: 0;
    margin-bottom: 0;
    opacity: 0.6;
  }
}