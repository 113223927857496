.directory {
  max-width: 80%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  color: white;
}

.backBtn {
  margin-bottom: -15px;
}

.backBtn:hover {
  cursor: pointer;
  text-decoration: underline;
}

.directory h1 {
  font-weight: 700;
}

.search {
  display: flex;
  margin-bottom: 15px;
}

.search input {
  display: block;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  font: inherit;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #2A3536;
  color: white;
}

.addBtn {
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  margin: 20px 0;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
}

.addBtn:hover {
  box-shadow: 0 0 5px white;
}

.typeList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.typeItem {
  flex: 0 0 calc(33.333% - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.typeItem:nth-child(3n) {
  margin-right: 0; /* reset margin for every 3rd item */
}

/* Responsive Design */
@media (max-width: 768px) {
  .typeItem {
    flex: 0 0 100%; /* full width for smaller screens */
    margin-right: 0; /* reset margin-right for mobile */
  }
}