.container {
  border-radius: 20px;
  padding: 5px 20px;
  background-color: rgb(29, 29, 29);
  margin-bottom: 25px;
}

.actionButton {
  margin-bottom: 15px;
}

.buttons {
  display: flex;
  gap: 50px;
}