.container {
    background-color: #2A3536;
    padding: 10px 20px;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.editInput {
    background-color: #3A4546;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;
    margin-right: 5px;
}

.editButton,
.editAction,
.participantsToggle,
.kickAction {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 25px;
    padding: 0 10px;
}

.editButton {
    transform: rotate(90deg);
}

.partticipantsList {
    width: 100%;
}

.participantItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    background-color: #3A4546;
    padding: 10px;
    border-radius: 10px;
    font-weight: 700;
}

.userList {
    margin-top: 1rem;
}

.userItem {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    background-color: #3A4546;
    padding: 10px;
    border-radius: 10px;
    font-weight: 700;
}

.checkbox {
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #8BAAAD;
    border-radius: 4px;
    margin-right: 1rem;
    cursor: pointer;
    flex-shrink: 0;
    position: relative; 
}

.checkbox:checked {
    background-color: var(--color-primary-01);
    border: none;
}

.checkbox:checked::after {
    content: '\2714';
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
}

.kickConfirmation {
    display: flex;
    align-items: center;
}

.kickAction {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.messageList {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0;
    margin: 0 0 20px 0;
    list-style-type: none;
}

.message {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.userMessage {
    justify-content: flex-end;
}

.messageContent {
    width: 80%;
    padding: 10px 15px;
    border-radius: 15px;
    color: white;
    word-wrap: break-word; /* Ensure long words break and wrap within the bubble */
    white-space: pre-wrap; /* Preserve whitespace and wrap text */
    overflow-wrap: break-word; /* Break long words to prevent overflow */
}

.otherUserMessage .messageContent {
    background-color: #3A4546;
    border-top-left-radius: 0;
}

.userMessage .messageContent {
    background-color: var(--color-primary-05);
    border-top-right-radius: 0;
    /* font-weight: 700; */
}

.avatar {
    width: 50px;
    height: 50%;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;
}

.inputForm {
    display: flex;
    align-items: center;
    margin-top: auto;
}

.charCount {
    width: 60px; /* Set a fixed width */
    text-align: right; /* Align text to the right */
    font-size: 14px;
    color: white; /* Default color */
    font-family: monospace; /* Use a monospace font for consistent character width */
    margin-right: 10px;
}

.messageInput {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #3A4546;
    color: white;
    resize: none; /* Prevent manual resizing */
    overflow-y: auto; /* Allow vertical scrolling */
    max-height: 96px; /* 4 lines max, assuming line-height is 24px */
    font-size: 16px;
}

.sendButton {
    background-color: transparent;
    border: none;
    color: var(--color-primary-05);
    font-size: 20px;
    cursor: pointer;
    padding: 0 10px;
}

.sendIcon {
    /* transform: rotate(-45deg); */
    display: inline-block;
    font-size: 30px;
}

.messageHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 5px;
}

.messageTimestamp {
    font-size: 12px;
    /* color: #999; */
    color: white;
}

.displayNameContainer {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.displayName {
    color: white;
    text-decoration: none;
}

.displayName:hover {
    text-decoration: underline;
    cursor: pointer;
}

.nonLinkDisplayName {
    color: white;
}

.participantName {
    display: inline-block;
}

.inboxHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* This will push items to the edges */
}

.inboxHeaderLeft {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.participantsToggle {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 25px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; /* Set a fixed height */
    width: 40px; /* Set a fixed width */
}

.editContainer {
    display: flex;
    align-items: center;
}

.editAction {
    padding: 5px 10px;
    margin-left: 5px;
    cursor: pointer;
}

.adminToggle,
.adminAction {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    padding: 0 5px;
    align-items: center;
    justify-content: center;
}

.adminBadge {
    font-size: 0.8em;
    color: var(--color-primary-05);
    margin-left: 5px;
}

.searchAndAddBar {
    position: sticky;
    top: 0;
    background-color: #2A3536;
    padding: 10px 0;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    /* Adds space between search input and button */
}

.search {
    margin-top: 0;
    flex-grow: 1;
    /* Allows the search input to take up available space */
    border-radius: 10px;
    font: inherit;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: var(--color-primary-06);
    color: white;
}

.addMembersButton {
    font: inherit;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: var(--color-primary-01);
    color: var(--color-primary-400);
    border: none;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    /* Prevents text from wrapping */
    flex-shrink: 0;
    /* Prevents button from shrinking */
    margin-top: 0;
    margin-left: 10px;
    /* Aligns with the search input */
}

.addMembersButton:hover {
    opacity: 0.8;
}

.addMembersButton:disabled {
    background-color: #4a5556;
    /* Darker gray for disabled state */
    color: #8a9596;
    /* Lighter gray for text */
    cursor: not-allowed;
    box-shadow: none;
}

.participantItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    background-color: #3A4546;
    padding: 10px;
    border-radius: 10px;
    font-weight: 700;
}

.participantName {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 10px;
}

.adminToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    /* margin-right: 8px; */
    position: relative;
}

.adminToggle::after {
    content: '\2026';
    /* Horizontal ellipsis character */
    font-size: 18px;
    line-height: 1;
    transform: translate(0, -35%);
}

.participantsList,
.addParticipantsContainer {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px); /* Adjust based on your header height */
    overflow: hidden;
}

.participantsList ul,
.userList {
    flex-grow: 1;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

/* Add this new class for the button container */
.participantsButtonContainer {
    display: flex;
    align-items: center;
}

.goBackButton,
.deleteInboxButton,
.changeImageButton,
.muteButton {
    font: inherit;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: var(--color-primary-01);
    color: var(--color-primary-400);
    border: none;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    flex-shrink: 0;
    margin-left: 10px;
    text-decoration: none;
}

.goBackButton:hover,
.deleteInboxButton:hover,
.changeImageButton:hover,
.muteButton:hover {
    opacity: 0.8;
}

.changeImageButton,
.muteButton {
    margin-right: 10px;
}

.participantsToggle {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 25px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; /* Set a fixed height */
    width: 40px; /* Set a fixed width */
}

.messageActions {
    display: flex;
    align-items: center;
    color: white;
}

.deleteButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.8em;
    margin-left: 5px;
    color: white;
}

.deleteConfirmation {
    display: flex;
    align-items: center;
    font-size: 0.8em;
    color: white;
}

.deleteAction {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 5px;
    color: white;
}

@media (max-width: 768px) {
    .inboxHeader {
        flex-direction: column;
        align-items: flex-start;
    }

    .inboxHeaderLeft {
        width: 100%;
        margin-bottom: 10px;
    }

    .participantsButtonContainer {
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
    }

    .goBackButton,
    .deleteInboxButton,
    .changeImageButton,
    .muteButton,
    .addMembersButton,
    .participantsToggle {
        margin: 0;
        flex-grow: 1;
        text-align: center;
    }

    .editContainer {
        width: 100%;
    }

    .editInput {
        flex-grow: 1;
    }

    .searchAndAddBar {
        display: block;
    }

    .goBackButton {
        width: 100%;
    }

    .searchAndAddBar .search,
    .searchAndAddBar .addMembersButton {
        width: 100%;
    }

    .search {
        margin-bottom: 5px;
    }
}