.actions {
  background-color: #ffffff;
  padding: 1rem;
  width: 30rem;
  border-radius: 6px;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirmations {
  display: flex;
  justify-content: center;
}

.actions h2,
.actions p {
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.actions button {
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
}

.enableBtn {
  background-color: #4EB84C;
}

.enableBtn:hover {
  background-color: #44a342;
}

.banBtn {
  background-color: #F85C5C;
}

.banBtn:hover {
  background-color: #d95555;
}

.roleBtn {
  background-color: #B4ACAC;
}

.roleBtn:hover {
  background-color: #a19a9a;
}

.actions a {
  text-decoration: underline;
  background-color: transparent;
  color: var(--color-primary-200);
}

.errors {
  max-width: 60%;
  margin: auto;
  padding: 0px 20px;
  border-radius: 10px;
  border: 2px solid var(--color-error-100);
  background-color: var(--color-error-200);
}

.errors p {
  text-align: center;
}

.info {
  display: flex;
  gap: 1rem;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  margin-top: -20px;
}

.email::before {
  content: "\2709"; /* Unicode character for envelope symbol */
  margin-right: 5px;
  font-size: 20px;
}

.orgType::before {
  content: "\2687"; /* Unicode character for envelope symbol */
  margin-right: 5px;
  font-size: 20px;
}

.phoneNumber::before {
  content: "\2706"; /* Unicode character for envelope symbol */
  margin-right: 5px;
  font-size: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .actions {
    max-width: 90%;
  }
}