.admin {
  max-width: 80%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  color: white;
}

.admin h1 {
  font-weight: 700;
}

.search {
  display: flex;
}

.search input {
  display: block;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  font: inherit;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #2A3536;
  color: white;
}

.filters {
  margin-top: 13px;
  margin-left: 15px;
  display: inline-flex;
  font-weight: 600;
  font-size: 18px;
}

.filters input[type="checkbox"] {
  margin-bottom: 17px;
  margin-right: 5px;
  transform: scale(1.5);
}

.filters label {
  margin-right: 15px;
  margin-left: 5px;
}

.usersList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.usersListItem {
  flex: 0 0 calc(33.333% - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.usersListItem:nth-child(3n) {
  margin-right: 0; /* reset margin for every 3rd item */
}

/* Responsive Design */
@media (max-width: 768px) {
  .search {
    flex-direction: column;
  }

  .filters {
    display: flex;
    flex-direction: column;
  }

  .usersListItem {
    flex: 0 0 100%; /* full width for smaller screens */
    margin-right: 0; /* reset margin-right for mobile */
  }
}