.container {
  display: flex;
  flex-direction: column;
  background-color: #2A3536;
  color: white;
  padding: 1rem;
  width: 35rem;
  border-radius: 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  padding-bottom: 30px;
  max-height: 100vh;
}

.container p {
  font-weight: bold;
}

.saveBtn {
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  margin-top: 10px;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
}

.saveBtn:hover {
  box-shadow: 0 0 5px white;
}

.disabledBtn {
  background-color: gray;
  color: white;
  cursor: not-allowed;
  border: none;
}

.crop {
  margin: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    width: inherit;
  }
}