.container {
  max-width: 80%;
  margin: auto;
  /* margin-top: 100px; */
  margin-bottom: 100px;
  color: white;
}

.backBtn:hover {
  cursor: pointer;
  text-decoration: underline;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #2A3536;
  border-radius: 15px;
}

.leftSection {
  display: flex;
}

.rightSection {
  display: flex;
}

.eventPicture {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 30px;
}

.titleSection h2 {
  font-size: 15px;
}

.titleSection hr {
  height: 1px;
  background-color: white;
  opacity: 0.2;
  margin-top: 25px;
}

.titleSection a {
  /* text-decoration: underline; */
  color: white;
}

.promotionName {
  cursor: pointer;
  text-decoration: underline;
}

.actionButtons {
  display: flex;
  margin: -1rem 0;
}

.actionButtons a {
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  margin: 20px 0;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
  margin-right: 15px;
}

.actionButtons a:hover {
  box-shadow: 0 0 5px white;
}

.submitLink {
  cursor: pointer;  
  font: inherit;
  text-decoration: none;
  margin: auto;
  padding: 0.5rem 1rem;
  width: fit-content;
  display: block;
  text-align: center;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
}

.submitLink:hover {
  box-shadow: 0 0 5px white;
}

.info {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}

.location::before {
  content: "\1F4CD"; /* Unicode character for location symbol */
  margin-right: 5px;
  font-size: 20px;
}

.dateTime::before {
  content: "\1F5D3"; /* Unicode character for clock symbol */
  margin-right: 5px;
  font-size: 20px;
}

.sports::before {
  content: "\1F94A"; /* Unicode character for boxing glove symbol */
  margin-right: 5px;
  font-size: 20px;
}

.tabsContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tab {
  background-color: #2a3536;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 5px;
  padding: 10px 20px;
  text-align: center;
}

.tab:hover {
  background-color: var(--color-primary-01);;
}

.activeTab {
  background-color: var(--color-primary-01);;
}

.fightsList {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.fightsListItem {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.availableFightersList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.availableFightersListItem {
  flex: 0 0 calc(33.333% - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.availableFightersListItem:nth-child(3n) {
  margin-right: 0; /* reset margin for every 3rd item */
}

.pending {
  text-align: center;
  margin-top: 50px;
}

/* .eventButton {
  flex: 0.25;
  text-align: center;
}

.inboxButton {
  flex: 0.53;
  text-align: center;
} */

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Stack the children vertically */
    align-items: center; /* Center-align the children horizontally */
    justify-content: center; /* Center-align the children vertically */
  }

  .leftSection {
    display: block;
    align-items: center;
  }

  .logoSection {
    display: flex; /* Use flexbox to allow center alignment */
    justify-content: center; /* Center the contents horizontally */
    width: 100%; /* Take full width to respect the centering */
    margin-top: 20px; /* Optional: add some margin on the top for spacing */
  }

  .eventPicture {
    margin-right: 0;
  }
  
  .rightSection {
    display: none;
  }

  .info li {
    margin-top: 10px;
  }

  .availableFightersListItem {
    flex: 0 0 100%; /* full width for smaller screens */
    margin-right: 0; /* reset margin-right for mobile */
  }

  .actionButtons a {
    flex: 1;
    text-align: center;
  }
}
