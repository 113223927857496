.tripleBannerAds {
    position: sticky;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

/* Desktop View: Arrange ads correctly */
.desktopView {
    display: flex;
    justify-content: space-between; /* Ensures left, center, and right ads are properly spaced */
    align-items: center;
    width: 100%;
    /* padding: 0 5%; */
}

.adLeft, .adRight, .adMain {
    display: flex;
    align-items: center; /* Ensures all ads are vertically aligned */
}

.adLeft {
    flex: 1;
    justify-content: flex-start; /* Moves the left ad to the far left */
}

.adMain {
    flex: 1;
    justify-content: center; /* Centers the main ad */
}

.adRight {
    flex: 1;
    justify-content: flex-end; /* Moves the right ad to the far right */
}

.mobileView {
    display: none;
}

/* Mobile: Only show the main banner */
@media (max-width: 768px) {
    .desktopView {
        display: none;
    }

    .mobileView {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}
