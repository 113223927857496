.container {
  width: 80%;
  color: white;
  margin: auto;
  margin-top: 100px;
}

.backBtn {
  margin-bottom: -15px;
}

.backBtn:hover {
  cursor: pointer;
  text-decoration: underline;
}

.search {
  display: flex;
  margin-bottom: 30px;
}

.search input {
  display: block;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  font: inherit;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #2A3536;
  color: white;
}

.scrollTopBtn {
  position: fixed;
  bottom: 20px;
  right: 100px;
  z-index: 100;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 16px;
}

.results h3::before {
  content: "\2687"; /* Unicode character for envelope symbol */
  margin-right: 5px;
  font-size: 20px;
}

.resultItem {
  padding: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(79, 79, 79);
}

.resultItem:last-child {
  border-bottom: none;
}

.viewBtn {
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
  margin-bottom: 10px;
}

.viewBtn:hover {
  box-shadow: 0 0 5px white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .scrollTopBtn {
    left: 0;
    right: 0;
    margin: auto;
    margin-left: 15%;
    margin-right: 15%;
  }
}
