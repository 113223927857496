.form {
  background-color: #2A3536;
  color: white;
  padding: 1rem;
  width: 20rem;
  border-radius: 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

.form h2 {
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.form label{
  display: block;
  margin-bottom: 0.05rem;
  color: white;
  font-weight: bold;
}

.cooldown {
  margin: auto;
  text-align: center;
}

.form input,
.form textarea {
  display: block;
  width: 100%;
  color: var(--color-primary-03);
  font: inherit;
  padding: 0.3rem;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgb(34, 34, 34);
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

.actions button {
  margin: 10px 10px;
  width: 50%;
  cursor: pointer;
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: var(--color-primary-400);
  font-weight: 700;
  font-size: 15px;
}

.actions button:hover {
  opacity: 0.5;
}

.actions a {
  text-decoration: underline;
  background-color: transparent;
  color: #ffffff;
}

.errors {
  max-width: 60%;
  margin: auto;
  border-radius: 10px;
  padding: 0px 20px;
  border: 2px solid var(--color-error-100);
  /* background-color: var(--color-error-200); */
}

.success {
  max-width: 60%;
  margin: auto;
  border-radius: 10px;
  border: 2px solid green;
}

.errors p,
.success p {
  text-align: center;
}

.errors ul,
.success ul {
  text-align: center;
  margin: 1rem 0rem 1rem 0rem;
}