.previewContainer {
  background-color: #2A3536;
  color: white;
  padding: 1rem;
  width: 20rem;
  border-radius: 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  padding-bottom: 30px;
}

.profilePicture {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 30px;
}

.info {
  margin-bottom: 20px;
}

.location::before {
  content: "\1F4CD"; /* Unicode character for location symbol */
  margin-right: 5px;
  font-size: 20px;
}

.dateTime::before {
  content: "\1F5D3"; /* Unicode character for clock symbol */
  margin-right: 5px;
  font-size: 20px;
}

.sports::before {
  content: "\1F94A"; /* Unicode character for boxing glove symbol */
  margin-right: 5px;
  font-size: 20px;
}

.viewMore {
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  margin: 30px 0;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
}

.viewMore:hover {
  box-shadow: 0 0 5px white;
}