.card {
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2A3536;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.card h1 {
  font-size: 17px;
  font-weight: 700;
  /* margin-bottom: -10px; */
}

.weightClass::before {
  content: "\1F3CB"; /* Unicode character for location symbol */
  margin-right: 5px;
  font-size: 20px;
}

.professional::before {
  content: "\1F4B8";
  margin-right: 5px;
  font-size: 20px;
}

.experience::before {
  content: "\1F5D3"; /* Unicode character for clock symbol */
  margin-right: 5px;
  font-size: 20px;
}

.proRecord::before {
  content: "\1F534";
  margin-right: 5px;
  font-size: 20px;
}

.amateurRecord::before {
  content: "\1F7E2";
  margin-right: 5px;
  font-size: 20px;
}