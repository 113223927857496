.card {
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2A3536;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.card h1 {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

.actionButton {
  cursor: pointer;
  font: inherit;
  width: 80%;
  margin-bottom: 20px;
  padding: 0.5rem 0rem;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
}

.actionButton:hover {
  box-shadow: 0 0 5px white;
}

.actionButton:disabled {
  background-color: gray;
  border: none;
  cursor: auto;
}

.actionButton:disabled:hover {
  box-shadow: none;
}