.container {
  max-width: 80%;
  margin: auto;
  /* margin-top: 100px; */
  margin-bottom: 100px;
  color: white;
}

.backBtn:hover {
  cursor: pointer;
  text-decoration: underline;
}

.header {
  display: flex;
  padding: 20px;
  background-color: #2A3536;
  border-radius: 15px;
}

.fighterPicture {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 30px;
}

.titleSection h2 {
  font-size: 15px;
}

.info {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}

.infoLinks {
  display: flex;
}

.infoLinks a {
  margin-right: 15px;
}

.titleSection a {
  text-decoration: underline;
  color: white;
}

.titleSection hr {
  height: 1px;
  background-color: white;
  opacity: 0.2;
  margin-top: 25px;
}

.actionButtons {
  display: flex;
  margin: -30px 0;
}

.actionButtons a {
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  margin: 20px 0;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-weight: 700;
  margin-right: 15px;
}

.actionButtons a:hover {
  box-shadow: 0 0 5px white;
}

.tabsContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tab {
  background-color: #2a3536;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 5px;
  padding: 10px 20px;
  text-align: center;
}

.tab:hover {
  background-color: var(--color-primary-01);;
}

.activeTab {
  background-color: var(--color-primary-01);;
}

.fightsList {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.fightsListItem {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.statsContainer {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.statsSection {
  flex: 0 0 calc(33.333% - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.statsSection:nth-child(3n) {
  margin-right: 0; /* reset margin for every 3rd item */
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Stack the children vertically */
    align-items: center; /* Center-align the children horizontally */
    justify-content: center; /* Center-align the children vertically */
  }

  .logoSection {
    display: flex; /* Use flexbox to allow center alignment */
    justify-content: center; /* Center the contents horizontally */
    width: 100%; /* Take full width to respect the centering */
    margin-top: 20px; /* Optional: add some margin on the top for spacing */
  }

  .fighterPicture {
    margin-right: 0;
  }

  .infoLinks a {
    line-height: normal; /* Resets any inherited line-height */
    height: auto; /* Resets any inherited height */
  }

  .info li {
    margin-top: 10px;
  }

  .statsSection {
    flex: 0 0 100%; /* full width for smaller screens */
    margin-right: 0; /* reset margin-right for mobile */
  }

  .actionButtons a {
    flex: 1;
    text-align: center;
  }
}
