.card {
  position: relative;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: #2A3536;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.fighterLogo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: contain;
  display: block;
}

.info {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  font-weight: 500;
  color: white;
  margin-left: 20px;
  width: 60%;
}

.info h1 {
  font-size: 17px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.info h2 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 20px;
}

.info p {
  margin-top: 5px;
  margin-bottom: 0;
  opacity: 0.6;
  font-size: 15px;
}

.actionButton {
  margin-top: 5px;
  cursor: pointer;
  font: inherit;
  width: 100%;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--color-primary-01);
  border-radius: 4px;
  background-color: var(--color-primary-01);
  color: white;
  font-size: 15px;
  font-weight: 700;
}

.actionButton:hover {
  box-shadow: 0 0 5px white;
}

.withdrawButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}