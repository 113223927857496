.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
}

.modal {
  position: fixed;
  top: 50%;
  transform: translate(0%, -50%);
  border: none;
  border-radius: 10px;
  padding: 0;
  z-index: 10001;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal {
    max-width: 80%;
  }
}